
// TODO: i18next breaks AOS animations, but not clue why.
// An AOS.refresh() at the right time could be the fix. but it is not clear

import React from 'react';
import {Divider} from '@material-ui/core';
import { graphql } from 'gatsby';

import Minimal from 'layouts/Minimal';
import WithLayout from 'layouts/WithChildrenLayout';
import { Section, SectionAlternate } from 'components/organisms';


import {CompanyData, References as references } from 'content';

import {images} from "../components/MediaLib";

import {ContactHero,ContactForm, References} from 'views';
import {ContactUs} from "partials";
import {SEO} from "components/seo";

const ContactPage = (props) => {
    // Translation temporarily disabled
    //const {t} = useTranslation();
    const t = (txt)=>txt;
    return (
        <WithLayout layout={Minimal}>
            <SEO title={t("Contact Valletech")}/>
            <ContactHero
                title={t("Contact us")}
                subtitle={t("We have over 20 years experience creating new Software Product, Services and Ventures.")}
                banner_image={images.develop_banner}
                />
            <References
                title={t("We have helped nice companies before, big and small alike.")}
                references={references}
                />
            <Divider/>
            <Section>
                <ContactForm
                    url={CompanyData.support_portal}
                    title={t("Your Details and Query")}
                    subtitle={t("We will get back to you ASAP")}
                    ticketTitle={t("Valletech Web Enquiry")}
                />
            </Section>
            <Section>
                <ContactUs/>
            </Section>
        </WithLayout>
    )
};

export default ContactPage;


export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {ns: {in: ["translation"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;